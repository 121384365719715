<template>
  <div @click="openModal" class="btn btn-primary--inverse" v-show="negoData">
    <v-icon>mdi-handshake-outline</v-icon>
    <span>{{ $t('negoHistory.btn') }}</span>
  </div>
</template>

<script>
export default {
  name: 'nego-history-btn',
  props: {
    listing: {
      required: true,
    },
    negoData: {
      required: true,
    },
  },
  mixins: [],
  computed: {},
  methods: {
    async openModal() {
      this.$modal.show('nego-history-modal', {
        listing: this.listing,
        negoData: this.negoData,
      });
    },
  },
};
</script>

<style scoped></style>
